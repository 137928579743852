import React, { Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";
import Loading from "../common-components/Loader";
import PageNotFound from "../components/Authentication/PageNotFound";
const Authentication =React.lazy(()=>import ("../components/Authentication/Authentication"));
const Dashboard = React.lazy(() => import("../components/dashboard/Dashboard"));
const AdminLogin = React.lazy(() => import("../components/auth/index"));
const ForgotPassword = React.lazy(
  () => import("../components/auth/forgotPassword/ForgotPassword")
);
const Layout = React.lazy(() => import("./layout"));
const NewBudgetRequest = React.lazy(
  () => import("../components/newbudetrequest/BudgetRequest")
);
const BudgetRequest = React.lazy(
  () => import("../components/budgetRequest/BudgetRequest")
);
const Invoices = React.lazy(() => import("../components/invoices/Invoices"));
const ProtectedRoute = React.lazy(() => import("./ProtectedRoute"));

const UserDetails = React.lazy(
  () => import("../components/UserManagement/UserDetails")
);

const ResetPasswordPage = React.lazy(
  () => import("../components/auth/Reset/Resetpassword")
);
const OnBoardingPage = React.lazy(
  () => import("../components/auth/onBoarding/Onboarding")
);
const InvoiceForm = React.lazy(
  () => import("../components/invoices/InvoiceForm")
);

const ViewDetails = React.lazy(
  () => import("../components/dashboard/ViewDetails")
);
const Landing = React.lazy(() => import("../components/Landing/Landing"));
const CoopSummary = React.lazy(
  () => import("../components/dashboard/CoopSummary")
);

export const routes = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    // loader:,
    children: [
      {
        path: "",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <Dashboard />
              </Suspense>
            }
          />
        ),
        // loader:,
      },
      {
        path: "/CoopSummary",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <CoopSummary />
              </Suspense>
            }
          />
        ),
      },
      {
        path: "/newBudgetReq/:budgetId?",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <NewBudgetRequest />
              </Suspense>
            }
          />
        ),
        // loader:,
      },
      // {
      //   path: "/login",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Loading
      //           isLoading={true}
      //           height={80}
      //           width={80}
      //           color="#15223F"
      //         ></Loading>
      //       }
      //     >
      //       <AdminLogin />
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: "/landing",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Loading
      //           isLoading={true}
      //           height={80}
      //           width={80}
      //           color="#15223F"
      //         ></Loading>
      //       }
      //     >
      //       <Landing />
      //     </Suspense>
      //   ),
      //   // loader:,
      // },

      {
        path: "/budgetReq/:id?",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <BudgetRequest />
              </Suspense>
            }
          />
        ),
        // loader:,
      },
      // {
      //   path: "/forgot-password",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Loading
      //           isLoading={true}
      //           height={80}
      //           width={80}
      //           color="#15223F"
      //         ></Loading>
      //       }
      //     >
      //       <ForgotPassword />
      //     </Suspense>
      //   ),
      //   // loader:,
      // },
      // {
      //   path: "onBoarding/:onBoardingToken",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Loading
      //           isLoading={true}
      //           height={80}
      //           width={80}
      //           color="#15223F"
      //         ></Loading>
      //       }
      //     >
      //       <OnBoardingPage />
      //     </Suspense>
      //   ),
      //   // loader:,
      // },
      // {
      //   path: "reset-password/:id",
      //   element: (
      //     <Suspense
      //       fallback={
      //         <Loading
      //           isLoading={true}
      //           height={80}
      //           width={80}
      //           color="#15223F"
      //         ></Loading>
      //       }
      //     >
      //       <ResetPasswordPage />
      //     </Suspense>
      //   ),
      //   // loader:,
      // },
      {
        path: "notAuthorized",
        element: (
          <Suspense
            fallback={
              <Loading
                isLoading={true}
                height={80}
                width={80}
                color="#15223F"
              ></Loading>
            }
          >
            <Authentication />
          </Suspense>
        ),
        // loader:,
      },
      {
        path: "invoices",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <Invoices />
              </Suspense>
            }
          />
        ),
        // loader:,
      },
      // {
      //     path:"UploadInvoices",
      //     element:<ProtectedRoute element={<Suspense fallback={<Loading isLoading={true} height={80} width={80} color="#15223F"></Loading>}><UploadInvoices/>}</Suspense> />
      // },
      {
        path: "/VerifyInvoices/:id",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <InvoiceForm />
              </Suspense>
            }
          />
        ),
      },
      {
        path: "usermanage",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <UserDetails />
              </Suspense>
            }
          />
        ),
      },
      {
        path: "viewDetails/:acc_num",
        element: (
          <ProtectedRoute
            element={
              <Suspense
                fallback={
                  <Loading
                    isLoading={true}
                    height={80}
                    width={80}
                    color="#15223F"
                  ></Loading>
                }
              >
                <ViewDetails />
              </Suspense>
            }
          />
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <Suspense
        fallback={
          <Loading
            isLoading={true}
            height={80}
            width={80}
            color="#15223F"
          ></Loading>
        }
      >
        <PageNotFound/>
      </Suspense>
    ),
    // loader:,
  },
 
]);